import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    GatsbyImage, getImage
} from 'gatsby-plugin-image';

const Card = styled.div`
    flex-grow: 0;
    flex-shrink: 0;
    width: 325px;
    overflow-y: hidden;
    margin: 3% 1%;

    @media (max-width: 767px) {
        width: 285px;
        margin-right:3%;
    }
    @media (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
        width:40%;
`;

const InfoCustomer = styled.div`
        display:flex;
        flex-direction:row;
`;

const CardText = styled.p`
    color: #2FAAE1;
    font-size:14px;
    margin-top: 0;
    margin-bottom: 1%;
    font-weight:300;
`;

const TextBy = styled.p`
    font-size:14px;
    font-weight:300;
    margin-top: 0;
    margin-bottom: 1%;
    margin-right:5px;
`;

const CardTitle = styled.h3`
    font-size: 17px;
    font-weight: 300;
    margin-top: 5%;
    margin-bottom: 2%;
    overflow-wrap: break-word;
`;

const Icon = styled.img`
    margin-right: 5px;
`;

const PictureCard = styled(GatsbyImage)`
    width: 100%;
    border-radius: 10px;
`;

const Link = styled.a`
    width: 100%;
    border-radius: 10px;
`;

const InfoPoint = styled.p`
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    margin-top:0px;
    margin-bottom:0px;
`;

const InfoView = styled.p`
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    margin-top:0px;
    margin-bottom:0px;
`;

const InfosMap = ({
    infoPoint, infoView
}) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row'
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginRight: '7%'
            }}>
                <Icon style={{ width: '12px' }}
                    src='/images/industries/placeholder-filled-point.svg'
                    alt='' />
                <InfoPoint dangerouslySetInnerHTML={{ __html: infoPoint }}></InfoPoint>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
            }}>
                <Icon style={{ width: '18px' }}
                    src='/images/industries/views.svg'
                    alt='' />
                <InfoView dangerouslySetInnerHTML={{ __html: infoView }}></InfoView>
            </div>
        </div>
    );
};

InfosMap.propTypes = {
    infoPoint: PropTypes.string,
    infoView: PropTypes.string
};

const CardCustomer = ({
    infosCard, title, text, by, picturecard, altPictureCard, url
}) => {

    const infosMap = infosCard.map(i => (<InfosMap infoPoint={i.infoPoint}
        key={i.infoPoint}
        infoView={i.infoView} />));


    return (
        <Card>
            <Link href={url}
                target='_blank'
                rel="noopener">
                <PictureCard image={getImage(picturecard)}
                    alt={altPictureCard} />
            </Link>
            <CardTitle dangerouslySetInnerHTML={{ __html: title }}></CardTitle>
            <InfoCustomer>
                <TextBy dangerouslySetInnerHTML={{ __html: by }}></TextBy>
                <CardText dangerouslySetInnerHTML={{ __html: text }}></CardText>
            </InfoCustomer>
            {infosMap}
        </Card>
    );
};

CardCustomer.propTypes = {
    infosCard: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    text: PropTypes.string,
    by: PropTypes.string,
    picturecard: PropTypes.object,
    altPictureCard: PropTypes.string,
    url: PropTypes.string
};

export default React.memo(CardCustomer);

