import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

// Layout component
import Layout from '../components/layouts/Layout';
import HeaderMap from '../components/HeaderMap';
import BannerPicture from '../components/BannerPicture';
import CustomersLogos from '../components/CustomersLogos';
import TwoColumnsIcons from '../components/TwoColumnsIcons';
import CardCustomer from '../components/CardCustomer';
import BackgroundPicture from '../components/BackgroundPicture';
import SectionOnlyFooter from '../components/SectionOnlyFooter';

const TwoColumnsLayout = styled.div`
    margin: 7% 0%;
`;

const CardCustomerLayout = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content:flex-start;
    margin: 7% 0%;


    @media (max-width: 767px) {
        flex-wrap:nowrap;
        overflow-x: auto;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
        touch-action: pan-x;
    }
`;

const BgDarkBlue = styled.div`
    position:absolute;
    left:0;
    z-index: -10000;
    background-color: #0c499b;
    width: 100%;
    height: 40.5%;
`;

const LivemapIframe = styled.div`
    display: flex;
    width: 100%;
    height: 560px;
    border-radius: 10px;
    overflow: hidden;
    border: none;
    box-shadow: 3px 3px 18px 3px rgba(99,99,99,.16863);
`;

const VideoIframe = styled.div`
    position: relative;
    padding-bottom: 53%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    border-radius: 10px;
    margin: 7% 0%;
`;

const IframeVideo = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
`;

const IndexPage = ({ data }) => {
    const { t } = useTranslation('mediaPage');

    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_MEDIA')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_MEDIA')}/>
            </Helmet>
            <Layout>

                <BackgroundPicture
                    image={data.background.childImageSharp}
                    alt='personne working with computer on grass'
                />

                <div className='wemap-page-index'>
                    <h1 className='wemap-page-index-title'
                        dangerouslySetInnerHTML={{ __html: t('TITLE') }}></h1>

                    <CustomersLogos
                        title={t('TITLE_POWER_WEBSITE')}
                        size='medium'
                        customersLogos={[
                            {image: '/images/products/wemap-web/france-3.svg'},
                            {image: '/images/industries/media/huma.svg'},
                            {image: '/images/industries/media/logo_20_minutes.png'},
                            {image: '/images/industries/media/public-senat-logo.png'},
                            {image: '/images/products/wemap-pro/depeche.svg'},
                            {image: '/images/industries/media/midilibre.svg'},
                            {image: '/images/industries/media/lci.svg'},
                            {image: '/images/industries/media/radio-france.svg'},
                            {image: '/images/products/wemap-web/ouest-france.svg'}
                        ]}
                    />


                    <SectionOnlyFooter
                        titleSection={t('TITLE_USER-CENTERED')}
                        cellParagraph={t('DESCRIPTION_SMART_MAPS_WIDGET')}
                        pictureRight={data.lci.childImageSharp}
                        altDescription='mobiles with Wemap map and pinpoints'
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        urlFooter='/contact/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-news-paper',
                                text: t('TEXT_LOCAL_NEWS')
                            },
                            {
                                icon: 'icon-spinner',
                                text: t('TEXT_PURE_PLAYERS')
                            }
                        ]}
                    />

                    <TwoColumnsLayout>
                        <TwoColumnsIcons
                            title={t('DESCRIPTION_CUSTOMERS')}
                            titleButton={t('BUTTON_LEARN_MORE')}
                            icons={[
                                { titleRight: t('DESCRIPTION_INTERACTIVE') },
                                {
                                    icon: 'icon-map3',
                                    text: t('TEXT_RICHER_ARTICLES')
                                },
                                {
                                    icon: 'icon-map3',
                                    text: t('TEXT_STICKY_CONTENT')
                                },
                                {
                                    icon: 'icon-location22',
                                    text: t('TEXT_SPECIFIC_PROJECTS')
                                }
                            ]}
                            urlFooter='/contact/'
                        />
                    </TwoColumnsLayout>

                    <VideoIframe>
                        <IframeVideo title="youtube"
                            width="101%"
                            height="100%"
                            src="https://www.youtube.com/embed/S29zJr6Gvl8"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                        </IframeVideo>
                    </VideoIframe>


                    <TwoColumnsLayout>
                        <TwoColumnsIcons
                            title={t('TITLE_BRAND')}
                            text={t('DESCRIPTION_MCC')}
                            titleButton={t('BUTTON_TALK_ABOUT_PROJECT')}
                            icons={[
                                {titleRight: t('DESCRIPTION_RECURRENCE')},

                                {
                                    icon: 'icon-news-paper',
                                    text: t('DESCRIPTION_CITY_GUIDES')
                                },

                                {
                                    icon: 'icon-address',
                                    text: t('DESCRIPTION_LEISURE')
                                },
                                {
                                    icon: 'icon-home2',
                                    text: t('DESCRIPTION_REAL_ESTATE_GUIDES')
                                },
                                {
                                    icon: 'icon-event_note',
                                    text: t('DESCRIPTION_SCALE_EVENTS')
                                }
                            ]}
                            leftIcon='/images/industries/media/telegram-logo.svg'
                            altColumnLogo='Logo Le Telegramme'
                            urlFooter='/contact/'
                        />
                    </TwoColumnsLayout>

                    <TwoColumnsLayout>
                        <TwoColumnsIcons
                            title={t('TITLE_INCREASE')}
                            text={t('DESCRIPTION_PARLEMENT_TRANSPARENT')}
                            titleButton={t('BUTTON_TALK_ABOUT_PROJECT')}
                            leftIcon='/images/industries/media/quotidien-logo.svg'
                            altColumnLogo='Logo Quotidien'
                            icons={[
                                { titleRight: t('DESCRIPTION_INTERACTIVE') },
                                {
                                    icon: 'icon-map3',
                                    text: t('DESCRIPTION_NATIONAL_ELECTIONS')
                                },
                                {
                                    icon: 'icon-share',
                                    text: t('DESCRIPTION_LOCAL_ELECTIONS')
                                }
                            ]}
                            urlFooter='/contact/'
                        />
                    </TwoColumnsLayout>

                    <BgDarkBlue></BgDarkBlue>
                    <HeaderMap
                        customerCicleImageUrl='/images/industries/media/icon-fr3-map.svg'
                        alt= 'Logo France 3'
                        titleCaseStudy={t('Case Study')}
                        title={t('TITLE_FRANCE3')}
                        by={t('TEXT_BY')}
                        text={t('TITLE_DESCRIPTION_FRANCE3')}
                        textPointInfos={t('TITLE_POINTS_FRANCE3')}
                        textViewsInfos={t('TITLE_VIEWS_FRANCE3')}
                        descriptionCustomer={t('TITLE_FRANCE3_DESCRIPTION_MAP')}
                        infosRight1={t('TITLE_DESCRIPTION_RIGHT_AF_0')}
                        infosRight2={t('TITLE_DESCRIPTION_RIGHT_AF_1')}
                        infosRight3={t('TITLE_DESCRIPTION_RIGHT_AF_2')} />


                    <LivemapIframe>
                        <iframe title="livemap"
                            width="100%"
                            height="100%"
                            src="https://livemap.getwemap.com/iframe.php?emmid=7216&token=7BP4TMOCO57KYC7J3YRHFVXUR"
                            allowFullScreen
                            frameBorder="0"></iframe>
                    </LivemapIframe>

                    <SectionOnlyFooter
                        cellParagraph={t('DESCRIPTION_INTERACTIVE_MAPPING')}
                        pictureRight={data.wemappro.childImageSharp}
                        altDescription='Wemap pro interface on computer'
                        titleButton={t('BUTTON_LEARN_MORE')}
                        urlFooter='/contact/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-news-paper',
                                text: t('TEXT_WEMAP_PRO')
                            },
                            {
                                icon: 'icon-spinner',
                                text: t('TEXT_WEMAP_SKD')
                            },
                            {
                                icon: 'icon-embed2',
                                text: t('TEXT_ADVANCED_SERVICE')
                            }
                        ]}
                    />

                    <CardCustomerLayout>
                        <CardCustomer
                            url='https://www.sudinfo.be/7dimanche/carte'
                            picturecard={data.dimanche.childImageSharp}
                            altPictureCard='La CNRS logo'
                            title={t('TITLE_DIMANCHE_MAP')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_DIMANCHE_MAP_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_DIMANCHE_MAP_POINT'),
                                    infoView: t('TITLE_DIMANCHE_MAP_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://esclavages.cnrs.fr/cartographie/'
                            picturecard={data.cnrs.childImageSharp}
                            altPictureCard='La CNRS logo'
                            title={t('TITLE_CNRS_MAP')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_CNRS_MAP_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_CNRS_MAP_POINT'),
                                    infoView: t('TITLE_CNRS_MAP_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://www.ladepeche.fr/2021/03/09/carte-dette-impots-depenses-votre-commune-est-elle-bien-geree-9417767.php'
                            picturecard={data.ladepecheimpot.childImageSharp}
                            altPictureCard='La Dépêche logo'
                            title={t('TITLE_IMPOT_MAP')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_IMPOT_MAP_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_IMPOT_MAP_POINT'),
                                    infoView: t('TITLE_IMPOT_MAP_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://agenda.letelegramme.fr/?app_bundle_search_type%5Bsimple_rubrique%5D=&app_bundle_search_type%5Bmot_cles%5D=&app_bundle_search_type%5Bdate_debut%5D=&app_bundle_search_type%5Bdate_fin%5D=&app_bundle_search_type%5Bid_commune%5D=&app_bundle_search_type%5Bhoneypot%5D=#/search@48.26395033977303,-3.1701467499999296,7.53186215489091'
                            picturecard={data.telegramme.childImageSharp}
                            altPictureCard='Le Télégramme logo'
                            title={t('TITLE_BOUGER_BRETAGNE')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_BOUGER_BRETAGNE_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_BOUGER_BRETAGNE_POINT'),
                                    infoView: t('TITLE_BOUGER_BRETAGNE_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://www.infolocale.fr/evenements?withMap=true#/aroundme'
                            picturecard={data.of.childImageSharp}
                            altPictureCard='Ouest France logo'
                            title={t('TITLE_INFO_LOCALE')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_INFO_LOCALE_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_INFO_LOCALE_POINT'),
                                    infoView: t('TITLE_INFO_LOCALE_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://ici.radio-canada.ca/correspondants-envoyes-speciaux-etranger-journalistes-monde-international-2018'
                            picturecard={data.radiocanada.childImageSharp}
                            altPictureCard='Radio Canada logo'
                            title={t('TITLE_RADIO_CANADA')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_RADIO_CANADA_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_RADIO_CANADA_POINT'),
                                    infoView: t('TITLE_RADIO_CANADA_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://www.tf1.fr/'
                            picturecard={data.mytf1.childImageSharp}
                            altPictureCard='my TF1 logo'
                            title={t('TITLE_MY_TF1')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_MY_TF1_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_MY_TF1_POINT'),
                                    infoView: t('TITLE_MY_TF1_VIEW')
                                }
                            ]}
                        />

                    </CardCustomerLayout>

                    <BannerPicture
                        backgroundBanner={data.banner.childImageSharp}
                        alt='Footer banner with an article on iPad'
                        titleBannerPicture={t('TITLE_BANNER_CASE_STUDIES')}
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        buttonUrl='/contact/' />

                </div>
            </Layout>
        </React.Fragment>
    );
};
IndexPage.propTypes = {data: PropTypes.object};

export default IndexPage;

export const query = graphql`{
  lci: file(relativePath: {eq: "industries/media/lci-livemap.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 470, layout: FIXED)
    }
  }
  background: file(relativePath: {eq: "background-image/media-bg.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  banner: file(relativePath: {eq: "industries/media/wemap_picture_media.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  dimanche: file(relativePath: {eq: "industries/media/picture_7dimanche.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  cnrs: file(relativePath: {eq: "industries/media/card-horizontal-cnrs.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  ladepecheimpot: file(
    relativePath: {eq: "industries/media/picture_ladepeche.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  telegramme: file(relativePath: {eq: "industries/media/picture_telegramme.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  of: file(relativePath: {eq: "industries/media/picture_ouestfrance.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  radiocanada: file(
    relativePath: {eq: "industries/media/picture_radiocanada.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  mytf1: file(relativePath: {eq: "industries/media/mytf1.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  mockup: file(relativePath: {eq: "products/indoor/mockup_indoor.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FIXED)
    }
  }
  wemappro: file(
    relativePath: {eq: "industries/media/laptop_ipad_livemap-v2.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 870, layout: FIXED)
    }
  }
}
`;
